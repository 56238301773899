<template>
  <a-layout-content
    style="margin: 0 16px; padding-bottom: 20px; height: calc(100vh - 64px); overflow-y: auto; box-sizing: border-box"
  >
    <a-breadcrumb style="margin: 16px 0">
      <a-breadcrumb-item v-for="(item, index) in breadcrumbList" :key="index">{{ item }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="page_main">
      <router-view />
    </div>
  </a-layout-content>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useRoute } from 'vue-router'
export default defineComponent({
  setup() {
    const route = useRoute()
    const breadcrumbList = computed(() => route.matched.map((v) => v.meta && v.meta.title))
    return {
      breadcrumbList,
    }
  },
})
</script>

<style lang="scss" scoped>
.page_main {
  padding: 24px;
  background-color: #fff;
  min-height: calc(100vh - 64px - 54px - 20px);
  transition: all 0.24s;
  box-sizing: border-box;
  position: relative; // 使首页图片固定的样式
}
</style>
