import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import getPageTitle from '@/utils/get-page-title'
import { getItem } from '@/utils/storage'
NProgress.configure({ showSpinner: true }) // NProgress Configuration
const whiteList = ['/login'] // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start()
  // set page title
  document.title = getPageTitle(to.meta.title)
  // determine whether the user has logged in
  const hasToken = getItem('role')
  if (hasToken) {
    if (to.path === '/login') {
      next('/')
      NProgress.done()
    } else {
      const hasRole = store.getters['user/role']
      if (hasRole) {
        next()
      } else {
        try {
          const uInfoRes = await store.dispatch('user/GetInfo')
          if (uInfoRes.role && uInfoRes.filterRouters) {
            // console.log("filterRouters::", uInfoRes.filterRouters);
            const accessRoutes = [
              ...uInfoRes.filterRouters,
              { path: '/:pathMatch(.*)', redirect: '/404', hidden: true },
            ]
            accessRoutes.forEach((r) => {
              router.addRoute(r)
            })
            store.commit('user/setRole', uInfoRes.role)
            store.commit('user/setMenuList', uInfoRes.filterRouters)
            next({ ...to, replace: true })
            NProgress.done()
          } else {
            next(`/login?redirect=${to.path}`)
            NProgress.done()
          }
        } catch (error) {
          await store.dispatch('user/resetAll')
          next(`/login?redirect=${to.path}`)
          NProgress.done()
        }
      }
    }
  } else {
    /* has no token*/
    if (whiteList.includes(to.path)) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
