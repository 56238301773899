<template>
  <div class="side_bar_wrap">
    <a-layout-sider v-model:collapsed="collapsed" collapsible>
      <Logo />
      <a-menu
        v-model:selectedKeys="menuSelectedKeys"
        v-model:openKeys="menuOpenKeys"
        theme="dark"
        mode="inline"
        @openChange="openChange"
      >
        <template v-for="(menu, index) in menuList" :key="index">
          <a-sub-menu :key="menu.path" v-if="!menu.hidden">
            <template #title>
              <span>
                <component :is="menu.iconTag ? menu.iconTag : ''" />
                <span>{{ menu.meta && menu.meta.title }}</span>
              </span>
            </template>
            <template v-for="childMenu in menu.children" :key="childMenu.path">
              <router-link :to="childMenu.path" v-if="!childMenu.hidden">
                <a-menu-item :key="childMenu.path">
                  {{ childMenu.meta && childMenu.meta.title }}
                </a-menu-item>
              </router-link>
            </template>
          </a-sub-menu>
        </template>
      </a-menu>
    </a-layout-sider>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import Logo from './Logo.vue'
import * as AntdIcon from '@ant-design/icons-vue'
export default defineComponent({
  components: {
    Logo,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const collapsed = ref(false)
    const menuSelectedKeys = ref([route.path])
    const menuOpenKeys = ref([route.matched[0].path])
    const menuList = computed(() => {
      // console.log(store.getters['user/menuList'])
      return store.getters['user/menuList'].map((v) => ({
        ...v,
        iconTag: v.meta.icon ? AntdIcon[v.meta.icon] : '',
      }))
    })
    const openChange = (openKeys) => {
      if (openKeys.length === 0) return
      menuOpenKeys.value = [openKeys[openKeys.length - 1]]
    }
    return {
      collapsed,
      menuList,
      menuOpenKeys,
      menuSelectedKeys,
      openChange,
    }
  },
})
</script>

<style lang="scss" scoped>
.side_bar_wrap {
  // height: 100vh;
  // overflow-y: auto;
  background-color: #011528;
}
</style>
