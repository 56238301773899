import request from '@/utils/request'

/**
 *
 * ---------------------------基本数据--------------------------
 */

// 查询省市区
export function fatchRegionTree(data) {
  return request({
    url: '/admin/basic/province/wxArea',
    method: 'get',
    data,
  })
}

// 所有分类-all
export function fatchQueryCategoryAll(data) {
  return request({
    url: '/admin/basics/erpCategory/all',
    method: 'get',
    data,
  })
}

/**
 * ------------------------------ 轮播图管理 ---------------------------------------
 */
/**
 *
 * @param {*} data
 * @returns
 * 分页查询轮播图
 */
export function queryBannerList(data) {
  return request({
    url: '/admin/system/banner',
    method: 'get',
    data,
  })
}

export function queryBannerOne(data) {
  return request({
    url: '/admin/user/getOneBanner',
    method: 'get',
    data,
  })
}

export function updateBannerOne(data) {
  return request({
    url: '/admin/system/banner',
    method: 'put',
    data,
  })
}

export function addBannerOne(data) {
  return request({
    url: '/admin/system/banner',
    method: 'post',
    data,
  })
}

export function delBannerOne(data) {
  return request({
    url: '/admin/system/banner',
    method: 'delete',
    data,
  })
}
// 修改状态
export function updateBannerStatus(data) {
  return request({
    url: '/admin/system/banner/updateStatus',
    method: 'put',
    data,
  })
}

/**
 * ---------------客户部分---------------------------------------
 */
// 查询客户列表-分页
export function queryCustomerList(data) {
  return request({
    url: '/admin/client',
    method: 'get',
    data,
  })
}

// 新增客户
export function fatchAddCustomerOne(data) {
  return request({
    url: '/admin/client',
    method: 'post',
    data,
  })
}

// 修改客户
export function fatchUpdateCustomerOne(data) {
  return request({
    url: '/admin/client',
    method: 'put',
    data,
  })
}
// 删除客户
export function fatchDeleteCustomerOne(data) {
  return request({
    url: '/admin/client',
    method: 'delete',
    data,
  })
}

// 查询客户-全部
export function queryCustAll(data) {
  return request({
    url: '/admin/client/getListByCondition',
    method: 'get',
    data,
  })
}
// 查询客户-全部 简易信息
export function queryCustAllList(data) {
  return request({
    url: '/admin/client/allForSearch',
    method: 'get',
    data,
  })
}
// 导入客户-全部
export function batchImportCustomer(data) {
  return request({
    url: '/admin/upload',
    method: 'post',
    data,
  })
}
// 更新用户个人信息-用来更新客户会员等级
export function updateUserInfo(data) {
  return request({
    data,
    method: 'PUT',
    url: '/admin/operation/modifyUserMessage',
  })
}
// 更新用户个人信息-给用户充值
export function updateUserPriceInfo(data) {
  return request({
    data,
    method: 'PUT',
    url: '/admin/operation/charge',
  })
}

/**
 *
 * 查询价格配置
 */
export function queryPriceConfigList(data) {
  return request({
    url: '/admin/business/ycysPriceGrade/getAllPriceGrade',
    method: 'get',
    data,
  })
}
export function updatePriceConfigList(data) {
  return request({
    url: '/admin/business/ycysPriceGrade/updateOne',
    method: 'put',
    data,
  })
}

/**
 *
 * 获取业务员或审核员role = ADMIN/AUDIT
 */
export function getAllAdminsList(data) {
  return request({
    url: '/admin/basic/getAllAdmins',
    method: 'get',
    data,
  })
}
/**
 *
 *优惠券
 */
export function getAllCouponList(data) {
  return request({
    url: '/admin/promote/coupon',
    method: 'get',
    data,
  })
}
export function getAllCouponListById(data) {
  return request({
    url: '/admin/promote/coupon/record/list',
    method: 'get',
    data,
  })
}
export function addAllCouponList(data) {
  return request({
    url: '/admin/promote/coupon',
    method: 'post',
    data,
  })
}
export function updateAllCouponList(data) {
  return request({
    url: '/admin/promote/coupon',
    method: 'put',
    data,
  })
}
export function deleteAllCouponList(data) {
  return request({
    url: '/admin/promote/coupon',
    method: 'delete',
    data,
  })
}
export function updateAllCouponStatus(data) {
  return request({
    url: '/admin/promote/coupon/updateCouponStatus',
    method: 'put',
    data,
  })
}
export function getAllCouponListOne(data) {
  return request({
    url: '/admin/promote/coupon/one',
    method: 'get',
    data,
  })
}
// 查询单个优惠券使用记录
export function queryOneCouponRecord(data) {
  return request({
    url: '/admin/promote/couponRecord',
    method: 'get',
    data,
  })
}

// 查询某订单已使用的优惠券列表 orderId
export function getOrderCouponListOne(data) {
  return request({
    url: '/admin/order/record/list',
    method: 'get',
    data,
  })
}

/***
 *
 *  物流信息
 */
export function getLogisticsList(data) {
  return request({
    url: '/admin/basic/logistics/queryPage',
    method: 'get',
    data,
  })
}
export function getAllLogisticsList(data) {
  return request({
    url: '/admin/basic/logistics/all',
    method: 'get',
    data,
  })
}

export function getLogisticsListOne(data) {
  return request({
    url: '/admin/basic/logistics/one',
    method: 'get',
    data,
  })
}
export function addLogisticsList(data) {
  return request({
    url: '/admin/basic/logistics/add',
    method: 'post',
    data,
  })
}
export function updateLogisticsList(data) {
  return request({
    url: '/admin/basic/logistics/update',
    method: 'put',
    data,
  })
}
export function deleteLogisticsList(data) {
  return request({
    url: '/admin/basic/logistics/delete',
    method: 'delete',
    data,
  })
}
/**
 *
 * 支付方式配置
 */

export function queryPaymentMode(data) {
  return request({
    url: '/admin/basic/payMethod/queryPage',
    method: 'get',
    data,
  })
}
export function queryPaymentModeAll(data) {
  return request({
    url: '/admin/basic/payMethod/all',
    method: 'get',
    data,
  })
}
export function addPaymentMode(data) {
  return request({
    url: '/admin/basic/payMethod/add',
    method: 'post',
    data,
  })
}
export function updatePaymentMode(data) {
  return request({
    url: '/admin/basic/payMethod/update',
    method: 'put',
    data,
  })
}
export function deletePaymentMode(data) {
  return request({
    url: '/admin/basic/payMethod/delete',
    method: 'delete',
    data,
  })
}

/***
 *
 * 传单工厂
 */
export function queryFactoryList(data) {
  return request({
    url: '/admin/basic/factory/queryPage',
    method: 'get',
    data,
  })
}
export function queryFactoryAllList(data) {
  return request({
    url: '/admin/basic/factory/all',
    method: 'get',
    data,
  })
}
export function queryFactoryListOne(data) {
  return request({
    url: '/admin/basic/factory/one',
    method: 'get',
    data,
  })
}
export function addFactoryList(data) {
  return request({
    url: '/admin/basic/factory/add',
    method: 'post',
    data,
  })
}
export function updateFactoryList(data) {
  return request({
    url: '/admin/basic/factory/update',
    method: 'put',
    data,
  })
}
export function deleteFactoryList(data) {
  return request({
    url: '/admin/basic/factory/delete',
    method: 'delete',
    data,
  })
}

/**
 *
 * 分销
 */
export function queryDistributionList(data) {
  return request({
    url: '/admin/operation/getAllDistributionUsers',
    method: 'get',
    data,
  })
}

export function queryDistributionListOne(data) {
  return request({
    url: '/admin/operation/getSubUsers',
    method: 'get',
    data,
  })
}
// 获取分销比例
export function queryDistributionRatio(data) {
  return request({
    url: '/admin/basic/ycysSetting/getDistributePercent',
    method: 'get',
    data,
  })
}
// 更改分销比例
export function updateDistributionRatio(data) {
  return request({
    url: '/admin/basic/ycysSetting/update',
    method: 'put',
    data,
  })
}

/**
 *
 * 提现转入
 */
export function queryWithdrawalList(data) {
  return request({
    url: '/admin/operation/withdrawQueryPage',
    method: 'get',
    data,
  })
}
// 提现通过
export function withdrawalAgree(data) {
  return request({
    url: '/admin/operation/withdrawAgree',
    method: 'put',
    data,
  })
}
// 提现拒绝
export function withdrawalDisagree(data) {
  return request({
    url: '/admin/operation/withdrawDisagree',
    method: 'put',
    data,
  })
}
