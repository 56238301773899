<template>
  <div style="border: 1px solid #ccc">
    <Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef" :defaultConfig="toolbarConfig" :mode="mode" />
    <Editor
      class="editor"
      :style="{ height: height + 'px' }"
      v-model="valueHtml"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="handleCreated"
      @onChange="handleChange"
    />
  </div>
</template>

<script>
import '@wangeditor/editor/dist/css/style.css' // 引入 css

import { onBeforeUnmount, ref, shallowRef, onMounted, computed } from 'vue'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'

export default {
  components: { Editor, Toolbar },
  props: {
    height: {
      type: Number,
      default: 500,
    },
  },
  emits: {
    onEditorChange: null,
  },
  setup(props, context) {
    // 编辑器实例，必须用 shallowRef
    const editorRef = shallowRef()

    // 内容 HTML
    const valueHtml = ref('')

    onMounted(() => {})

    const toolbarConfig = {}
    const editorConfig = {
      placeholder: '请输入内容...',
      MENU_CONF: {},
    }

    // 选择删除
    toolbarConfig.excludeKeys = [
      'group-video',
      'uploadVideo',
      'editVideoSize',
      'insertVideo',
      'codeBlock',
      'code',
      'fullScreen',
      'todo',
    ]

    // 开发环境
    const actionsEnvironment = computed(() => {
      if (import.meta.env === 'development') {
        return '/pecp/admin/upload/img'
      } else {
        return '/admin/upload/img'
      }
    })
    const actionsEnvironmentVideo = computed(() => {
      if (import.meta.env === 'development') {
        return '/pecp/admin/upload/video'
      } else {
        return '/admin/upload/video'
      }
    })
    // 配置上传图片、视频
    editorConfig.MENU_CONF['uploadVideo'] = {
      server: actionsEnvironmentVideo.value,
      fieldName: 'file',
      customInsert(res, insertFn) {
        insertFn(res.data, res.alt, res.href)
      },
    }
    editorConfig.MENU_CONF['uploadImage'] = {
      server: actionsEnvironment.value,
      fieldName: 'file',
      // 自定义插入图片
      customInsert(res, insertFn) {
        insertFn(res.data, res.alt, res.href)
      },
    }

    // 组件销毁时，也及时销毁编辑器
    onBeforeUnmount(() => {
      const editor = editorRef.value
      if (editor == null) return
      editor.destroy()
    })

    const handleCreated = (editor) => {
      editorRef.value = editor // 记录 editor 实例，重要！
      // console.log('vvv:::', editor.getAllMenuKeys())
    }

    const handleChange = (editor) => {
      const htmlValue = editor.getHtml()
      context.emit('onEditorChange', htmlValue || '')
    }

    const setEditorVal = (val) => {
      valueHtml.value = val || ''
    }

    return {
      editorRef,
      valueHtml,
      mode: 'default', // 或 'simple'
      toolbarConfig,
      editorConfig,
      handleCreated,
      handleChange,
      setEditorVal,
    }
  },
}
</script>

<style lang="scss" scoped>
.editor {
  overflow-y: hidden;
}
</style>
